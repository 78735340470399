import {
  ExclaimationCircleIcon,
  CurrencyDollarIcon,
  PaperAirplaneIcon,
  ClockIcon,
} from '@heroicons/react/24/outline'
import * as React from 'react'
import format from 'date-fns/format'

import { classNames, currencyFormat } from '../utils'
import usePayrollTransactions from '../hooks/usePayrollTransactions'
import usePaymentsBalance from '../hooks/usePaymentsBalance'
import useAccounts from '../hooks/useAccounts'
import { Account } from '../types'
import Spinner from '../components/Spinner'

function AccountInfo(props: { id: Account['_id']; isCanceled?: boolean }) {
  const accounts = useAccounts()
  const account = accounts.data?.find((a) => a._id === props.id)
  return account ? (
    <div
      className={classNames(
        'mt-0.5 text-xs',
        props.isCanceled ? 'text-slate-400 line-through' : 'text-slate-500'
      )}
    >
      Sent to your{' '}
      <strong>
        {account.type === 'payoneer' ? 'Payoneer' : account.alias}
      </strong>{' '}
      account
    </div>
  ) : null
}

export default function PaymentsHistoryPage() {
  const transactions = usePayrollTransactions()
  const balance = usePaymentsBalance()

  return (
    <div className="max-w-2xl">
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusnt
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>

      {typeof balance.data?.balance === 'number' && (
        <div className="-mx-3 mt-5 flex w-full justify-between rounded-md bg-slate-100 px-3 py-4 font-semibold">
          Your balance <div>{currencyFormat.format(balance.data.balance)}</div>
        </div>
      )}

      {transactions.data && transactions.data.length > 0 && (
        <table className="-mx-3 mt-6 w-full text-sm">
          <thead>
            <tr className="text-slate-500">
              <th className="border-b py-1.5 pl-3 text-left font-semibold">
                Type
              </th>
              <th className="border-b px-3 py-1.5 text-left font-semibold">
                Description
              </th>
              <th className="border-b px-3 py-1.5 text-right font-semibold">
                Amount
              </th>
              <th className="border-b px-3 py-1.5 text-left font-semibold">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.data.map((tx) => {
              return (
                <tr key={tx._id}>
                  <td className="border-b py-3 pl-3 align-baseline text-slate-700">
                    {tx.type === 'credit' && (
                      <span className="relative top-1 flex gap-1 whitespace-nowrap align-baseline text-xs font-semibold uppercase text-green-600">
                        <CurrencyDollarIcon className="h-4 w-4" /> {tx.type}
                      </span>
                    )}
                    {tx.type === 'discount' && (
                      <span className="relative top-1 flex gap-1 whitespace-nowrap align-baseline text-xs font-semibold uppercase text-red-600">
                        <CurrencyDollarIcon className="h-4 w-4" /> {tx.type}
                      </span>
                    )}
                    {tx.type === 'debit' && (
                      <span
                        className={classNames(
                          'relative top-1 flex gap-1 whitespace-nowrap align-baseline text-xs font-semibold uppercase',
                          tx.status === 'Canceled'
                            ? 'text-slate-400'
                            : 'text-blue-500'
                        )}
                      >
                        <PaperAirplaneIcon className="h-4 w-4" /> {tx.type}
                      </span>
                    )}
                  </td>
                  <td className="border-b px-3 py-3 align-baseline">
                    <span
                      className={classNames(
                        tx.status === 'Canceled'
                          ? 'text-slate-400 line-through'
                          : 'text-slate-700'
                      )}
                    >
                      {tx.description}
                    </span>{' '}
                    {tx.status === 'Canceled' && (
                      <span className="ml-2 rounded-md border bg-slate-100 px-1 py-1 text-[10px] font-semibold uppercase text-slate-500">
                        cancelled
                      </span>
                    )}
                    {tx.bankAccountId && (
                      <AccountInfo
                        isCanceled={tx.status === 'Canceled'}
                        id={tx.bankAccountId}
                      />
                    )}
                  </td>
                  <td
                    className={classNames(
                      'border-b px-3 py-3 text-right align-baseline',
                      tx.status === 'Canceled'
                        ? 'text-slate-400 line-through'
                        : tx.type === 'credit'
                        ? 'text-green-700'
                        : tx.type === 'discount'
                        ? 'text-red-600'
                        : ''
                    )}
                  >
                    {currencyFormat.format(
                      tx.amount * (tx.type === 'discount' ? -1 : 1)
                    )}
                  </td>
                  <td className="whitespace-nowrap border-b px-3 py-3 align-baseline text-slate-700">
                    {tx.completedOn ? (
                      format(tx.completedOn, 'PPP')
                    ) : tx.canceledOn ? (
                      format(tx.canceledOn, 'PPP')
                    ) : (
                      <span className="flex items-baseline gap-1">
                        <ClockIcon className="relative top-[2.5px] h-4 w-4 text-slate-400" />{' '}
                        <span className="text-xs italic text-slate-400">
                          Processing
                        </span>
                      </span>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}

      {transactions.isLoading && <Spinner className="mx-auto my-12" />}

      {transactions.data && transactions.data.length === 0 && (
        <p className="my-8 text-center italic text-slate-400">
          No transactions to display{' '}
        </p>
      )}

      {transactions.error && (
        <div className="mt-6 rounded-md bg-red-100 px-4 py-4 text-sm text-red-800">
          <ExclaimationCircleIcon className="-ml-0.5 mr-1 inline-block h-4 w-4 align-middle" />
          <strong className="font-semibold">Oops...</strong>
          <p className="mt-2">{transactions.error.message}</p>
        </div>
      )}
    </div>
  )
}
