import { isFuture, isPast, isSameDay } from 'date-fns'
import ExclaimationCircleIcon from '@heroicons/react/24/outline/ExclaimationCircleIcon'
import CurrencyDollarIcon from '@heroicons/react/24/outline/CurrencyDollarIcon'
import { XCircleIcon } from '@heroicons/react/24/solid'
import * as React from 'react'
import partition from 'ramda/src/partition'
import { Link } from 'react-router-dom'
import format from 'date-fns/format'

import { classNames, currencyFormat } from '../utils'
import { MAX_PTO_DAYS_PER_REQUEST } from '../config'
import { cancelTimeOffRequest } from '../api/timeoffApi'
import useTimeOffTransactions from '../hooks/useTimeOffTransactions'
import useTimeOffRequests from '../hooks/useTimeOffRequests'
import { TimeOffRequest } from '../types'
import useActiveContract from '../hooks/useActiveContract'
import useTimeOffBalance from '../hooks/useTimeOffBalance'
import { useToasts } from '../components/ToastsProvider'
import useHolidays from '../hooks/useHolidays'
import BaseDialog from '../components/BaseDialog'
import Spinner from '../components/Spinner'
import Tooltip from '../components/Tooltip'

export default function TimeOffOverviewPage() {
  const timeOffBalance = useTimeOffBalance()
  const { addToast } = useToasts()
  const requests = useTimeOffRequests()
  const activeContract = useActiveContract()
  const transactions = useTimeOffTransactions()
  const holidays = useHolidays()

  const [selectedRequest, setSelectedRequest] =
    React.useState<TimeOffRequest | null>(null)

  const [action, setAction] = React.useState<'cancel' | null>(null)

  const handleCancelRequestConfirmation = () => {
    const requestId = selectedRequest?._id
    if (!requestId) return
    setAction(null)
    requests.mutate(
      async () => {
        await cancelTimeOffRequest(requestId)
          .then(() => addToast('Request cancelled', { variant: 'success' }))
          .catch((err) =>
            addToast(err.message, { variant: 'danger', autoCloseDelay: 5000 })
          )
        return undefined
      },
      {
        optimisticData: (items = []) => {
          return items.map((item) =>
            item._id === requestId ? { ...item, status: 'Canceled' } : item
          )
        },
        rollbackOnError: true,
        // the response from DELETE is not what we want to update the cache
        populateCache: false,
      }
    )
  }

  const [pastRequests, currentRequests] = partition(
    ({ dates }) => dates.every((day) => isPast(day)),
    requests.data || []
  )

  const [isPastRequestsVisible, setIsPastRequestsVisible] =
    React.useState(false)

  return (
    <div>
      <div className="flex flex-col items-start gap-4 lg:flex-row">
        {activeContract.isLoading && (
          <div className="order-1 w-full shrink-0 lg:max-w-[240px]">
            <Spinner size="small" className="mx-auto" />
          </div>
        )}
        {activeContract.data && holidays.data && (
          <aside className="order-1 mt-2 shrink-0 rounded-md bg-slate-100 p-4 lg:-mt-14 lg:max-w-[240px]">
            <h2 className="mb-3 font-bold text-slate-600">
              Our time-off policy
            </h2>
            <p className="text-sm">
              As a Southie, you have{' '}
              <strong>
                {activeContract.data.ptoAccruedAutomaticallyAfterFirstYear}{' '}
                working{' '}
                {activeContract.data.ptoAccruedAutomaticallyAfterFirstYear === 1
                  ? 'day'
                  : 'days'}
              </strong>{' '}
              of paid time off per year, with a{' '}
              <strong>
                {activeContract.data.ptoCarryOverLimit}{' '}
                {activeContract.data.ptoCarryOverLimit === 1 ? 'day' : 'days'}
              </strong>{' '}
              maximum carry over policy for unused time off.
            </p>
            <p className="mt-3 text-sm">
              You can ask for up to{' '}
              <strong>{MAX_PTO_DAYS_PER_REQUEST} days</strong> off per request.
            </p>
            <p className="mt-3 text-sm">
              PTOs will be accrued on a{' '}
              <strong>
                {activeContract.data.ptoAccruedPerMonthDuringFirstYear}{' '}
                {activeContract.data.ptoAccruedPerMonthDuringFirstYear === 1
                  ? 'day'
                  : 'days'}
              </strong>{' '}
              per month worked basis during your first year.
            </p>
            <p className="mt-3 text-sm">
              Southteams will also provide payment for time off on{' '}
              <strong>{holidays.data.length} national holidays</strong>.
            </p>
          </aside>
        )}

        <div className="mt-2 grid grow gap-1 md:grid-cols-2 md:gap-4 lg:max-w-prose">
          <div>
            <h2 className="text-sm font-semibold text-slate-500">
              Available PTO to date
            </h2>
            {timeOffBalance.data && (
              <div>
                <div className="mb-1 mt-2 text-2xl font-normal text-slate-700 lg:text-3xl">
                  {timeOffBalance.data.available}{' '}
                  {timeOffBalance.data.available === 1 ? 'day' : 'days'}
                </div>
                {timeOffBalance.data.accrued > 0 && (
                  <ol className="ml-4 text-xs text-slate-500">
                    <li>
                      <div className="relative -top-1 mr-2 inline-block h-5 w-4 border border-r-0 border-t-0 border-dashed border-slate-400" />
                      <span className="font-mono text-green-600">
                        <span className="opacity-0">+</span>
                        {timeOffBalance.data.accrued < 10 && <>&nbsp;</>}
                        {timeOffBalance.data.accrued}
                      </span>{' '}
                      accrued this period
                    </li>
                    {timeOffBalance.data.carriedOverFromLastYear > 0 && (
                      <li className="-mt-1">
                        <div className="relative -top-1 mr-2 inline-block h-6 w-4 border border-r-0 border-t-0 border-dashed border-slate-400" />
                        <span className="font-mono text-green-600">
                          +
                          {timeOffBalance.data.carriedOverFromLastYear < 10 && (
                            <>&nbsp;</>
                          )}
                          {timeOffBalance.data.carriedOverFromLastYear}
                        </span>{' '}
                        carried over from previous period
                      </li>
                    )}
                    {timeOffBalance.data.consumed > 0 && (
                      <li className="-mt-1">
                        <div className="relative -top-1 mr-2 inline-block h-6 w-4 rounded-bl-md border border-r-0 border-t-0 border-dashed border-slate-400" />
                        <span className="font-mono text-orange-500">
                          -{timeOffBalance.data.consumed < 10 && <>&nbsp;</>}
                          {timeOffBalance.data.consumed}
                        </span>{' '}
                        consumed this period
                      </li>
                    )}
                  </ol>
                )}
              </div>
            )}
            <div className="mt-2">
              {!timeOffBalance.data && !timeOffBalance.error && (
                <div className="my-4 text-2xl">
                  <Spinner size="small" />
                </div>
              )}
              {timeOffBalance.error && !timeOffBalance.data && (
                <div className="my-3 text-sm text-red-800">
                  <ExclaimationCircleIcon className="-ml-0.5 mr-0.5 inline-block h-4 w-4 align-middle" />{' '}
                  {timeOffBalance.error.message}
                </div>
              )}
              <Link
                className="text-sm text-sky-600 underline underline-offset-2 outline-none ring-offset-2 hover:text-sky-700 focus-visible:ring-2 sm:text-xs"
                to="/timeoff/request"
              >
                Request a PTO
              </Link>
            </div>
          </div>

          <div className="mt-6 md:mt-0">
            <h2 className="text-sm font-semibold text-slate-500">
              Requested Time-off
            </h2>
            {requests.data && (
              <>
                {currentRequests.length ? (
                  <ol className="ml-0 mt-3 space-y-3 lg:space-y-4">
                    {currentRequests.map((pto) => {
                      const bg = {
                        Approved: 'bg-green-300',
                        'Waiting for approval': 'bg-orange-200',
                        Canceled: 'bg-slate-200',
                        Rejected: 'bg-red-300',
                      }[pto.status]
                      const color = {
                        Approved: 'text-green-800',
                        'Waiting for approval': 'text-slate-600',
                        Canceled: 'text-slate-500',
                        Rejected: 'text-slate-700',
                      }[pto.status]
                      const totalCost = pto.dates.reduce((sum, day) => {
                        const tx = (transactions.data || []).find(
                          (t) =>
                            isSameDay(day, t.date) &&
                            t.type === 'credit' &&
                            t.employeeBought
                        )
                        return sum + (tx?.dayValue || 0)
                      }, 0)
                      return (
                        <li key={pto._id}>
                          <div className="text-base text-slate-700 lg:text-sm">
                            <div className="inline-flex flex-wrap gap-x-2">
                              {pto.dates.map((day, i) => {
                                const tx = (transactions.data || []).find(
                                  (t) =>
                                    isSameDay(day, t.date) &&
                                    t.type === 'credit' &&
                                    t.employeeBought
                                )
                                const isNotCovered = Boolean(tx)
                                return (
                                  <span
                                    className={classNames(
                                      isNotCovered
                                        ? 'text-red-600'
                                        : isPast(day) && 'text-slate-400',
                                      'whitespace-nowrap text-xs'
                                    )}
                                    key={day.toISOString()}
                                  >
                                    {isNotCovered ? (
                                      <Tooltip
                                        content={
                                          <div>
                                            Not covered by PTO policy
                                            <div>
                                              Cost:{' '}
                                              {currencyFormat.format(
                                                tx?.dayValue || 0
                                              )}
                                            </div>
                                          </div>
                                        }
                                      >
                                        <span className="cursor-help">
                                          {format(day, 'MMM do')}
                                          <CurrencyDollarIcon className="-mt-0.5 ml-0.5 inline h-3 w-3 opacity-80" />
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      format(day, 'MMM do')
                                    )}

                                    <span className="text-slate-400">
                                      {i < pto.dates.length - 1 ? ', ' : ''}
                                    </span>
                                  </span>
                                )
                              })}
                            </div>
                            {totalCost > 0 && (
                              <div className="mt-0.5 text-xs text-red-700/80">
                                Total Cost: {currencyFormat.format(totalCost)}
                              </div>
                            )}
                            <div className="mt-1">
                              <span
                                className={classNames(
                                  '-ml-1 rounded-md px-1.5 py-1 text-xs',
                                  color,
                                  bg
                                )}
                              >
                                {
                                  {
                                    Approved: (
                                      <>
                                        Approved{' '}
                                        {pto.dates.every((day) =>
                                          isFuture(day)
                                        ) && (
                                          <Tooltip content="Cancel Request">
                                            <button
                                              aria-label="Cancel Request"
                                              className="relative -top-0.5 -mx-1 -my-1 px-1.5 py-1 align-middle text-green-800/50 hover:text-green-800"
                                              onClick={() => {
                                                setSelectedRequest(pto)
                                                setAction('cancel')
                                              }}
                                              type="button"
                                            >
                                              <XCircleIcon className="w-4" />
                                            </button>
                                          </Tooltip>
                                        )}
                                      </>
                                    ),
                                    'Waiting for approval': (
                                      <>
                                        Pending approval{' '}
                                        <Tooltip content="Cancel Request">
                                          <button
                                            aria-label="Cancel Request"
                                            className="relative -top-0.5 -mx-1 -my-1 px-1.5 py-1 align-middle text-orange-800/50 hover:text-orange-800"
                                            onClick={() => {
                                              setSelectedRequest(pto)
                                              setAction('cancel')
                                            }}
                                            type="button"
                                          >
                                            <XCircleIcon className="w-4" />
                                          </button>
                                        </Tooltip>
                                      </>
                                    ),
                                    Canceled: 'Cancelled',
                                    Rejected: 'Rejected',
                                  }[pto.status]
                                }
                              </span>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ol>
                ) : (
                  <div className="my-3 text-sm text-slate-500">
                    No upcoming time-off requests
                  </div>
                )}

                {pastRequests.length > 0 && !isPastRequestsVisible && (
                  <button
                    className="mt-6 text-sm text-sky-600 underline underline-offset-2 outline-none ring-offset-2 hover:text-sky-700 focus-visible:ring-2 sm:text-xs"
                    onClick={() => setIsPastRequestsVisible(true)}
                    type="button"
                  >
                    View past requests
                  </button>
                )}

                {pastRequests.length > 0 && isPastRequestsVisible && (
                  <ol className="ml-0 mt-6 space-y-3 border-t pt-4 lg:space-y-4">
                    {pastRequests.map((pto) => {
                      if (pto.dates.length === 0) return null // shouldn't be necessary, but there are some empty requests in DB
                      return (
                        <li key={pto._id}>
                          <div className="text-base text-slate-700 lg:text-sm">
                            <div className="inline-flex flex-wrap gap-x-2">
                              {pto.dates.map((day, i) => {
                                return (
                                  <span
                                    className="whitespace-nowrap text-xs text-slate-400"
                                    key={day.toISOString()}
                                  >
                                    {format(day, 'MMM do')}
                                    {i < pto.dates.length - 1 ? ', ' : ''}
                                  </span>
                                )
                              })}
                            </div>
                            <div className="mt-1">
                              <span className="-ml-1 rounded-md bg-slate-200 px-1.5 py-1 text-xs text-slate-400">
                                {
                                  {
                                    Approved: 'Approved',
                                    'Waiting for approval': 'Pending approval',
                                    Canceled: 'Cancelled',
                                    Rejected: 'Rejected',
                                  }[pto.status]
                                }
                              </span>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ol>
                )}
              </>
            )}
            {!requests.data && !requests.error && (
              <div className="my-4 text-2xl">
                <Spinner size="small" />
              </div>
            )}
            {requests.error && !requests.data && (
              <div className="my-3 text-sm text-red-800">
                <ExclaimationCircleIcon className="-ml-0.5 mr-0.5 inline-block h-4 w-4 align-middle" />{' '}
                {requests.error.message}
              </div>
            )}
          </div>
        </div>
      </div>

      <BaseDialog
        noCloseButton
        afterLeave={() => setSelectedRequest(null)}
        onClose={() => setAction(null)}
        isOpen={action === 'cancel'}
        title="Cancel Time-Off Request"
        size="sm"
      >
        <div className="mt-4">
          <p className="text-sm text-slate-500">
            Are you sure you that want to cancel this request?
          </p>

          <ul className="mb-8 mt-4 list-disc space-y-2 pl-4 text-base font-semibold text-slate-600 marker:text-red-500">
            {selectedRequest?.dates.map((day, i) => {
              return (
                <li key={day.toISOString()}>
                  <span className="whitespace-nowrap" key={day.toISOString()}>
                    {format(day, 'PPP')}
                  </span>
                </li>
              )
            })}
          </ul>

          <div className="mt-6 flex gap-4">
            <button
              className="rounded-md bg-red-500 px-6 py-3 text-sm font-semibold text-white outline-none ring-red-400 ring-offset-2 hover:bg-red-600 focus-visible:ring-2 disabled:bg-red-300"
              onClick={handleCancelRequestConfirmation}
            >
              Cancel Request
            </button>
            <button
              className="rounded-md px-4 py-3 text-sm text-slate-500 underline decoration-slate-400 underline-offset-1 outline-none hover:text-slate-700 focus-visible:ring-2"
              onClick={() => setAction(null)}
            >
              Close
            </button>
          </div>
        </div>
      </BaseDialog>
    </div>
  )
}
