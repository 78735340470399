import { fromZodError } from 'zod-validation-error'
import { googleLogout } from '@react-oauth/google'
import { ZodSchema, ZodTypeDef } from 'zod'
import { format } from 'date-fns'

import { tokenStorageKey, userStorageKey } from './config'
import { GENERIC_ERROR_MESSAGE } from './api/common'
import { PageError } from './types'

export function classNames(
  ...classes: (string | false | null | undefined)[]
): string {
  return classes.filter(Boolean).join(' ')
}

export const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'code',
})

export function validateResponse<Output>(
  schema: ZodSchema<Output, ZodTypeDef, unknown>,
  errorMessage = GENERIC_ERROR_MESSAGE
) {
  return async function (response: Response): Promise<Output> {
    if (!response.ok) {
      const error: PageError = new Error(errorMessage)
      error.status = response.status
      try {
        error.info = await response.json()
      } catch (e) {
        error.info = { message: response.statusText }
      }
      throw error
    }
    const res = await response.json()
    const result = schema.safeParse(res)
    if (result.success) {
      return result.data
    }
    console.warn(
      fromZodError(result.error, { prefix: `(URL: ${response.url})` })
    )
    return res
  }
}

export function signOut(): void {
  googleLogout()
  // once logged out from Google, clear the browser storage
  window.localStorage.removeItem(tokenStorageKey)
  window.localStorage.removeItem(userStorageKey)
  // force a refresh to clear all of SWR cache
  window.location.reload()
}

export function checkAuth(response: Response): Response {
  if (response.status === 401) signOut()
  return response
}

/**
 * Handles the case where the API sends dates in `yyyy-MM-dd` format
 *
 * We need to append a time string to the date, otherwise the Date object
 * will be considered UTC and when formatting back to ISO we might end up
 * on the previous or following day.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#sect2
 */
export function dateFromIsoString(isoString: string): Date {
  return new Date(isoString.split('T')[0] + 'T00:00:00')
}

/**
 * Converts a Date or timestamp in browser's timezone into a UTC-0 timestamp
 */
export const toUtc0Timestamp = (dateOrTimestamp: Date | number): number => {
  const utc0IsoString = `${format(dateOrTimestamp, 'yyyy-MM-dd')}T00:00:00.000Z`
  return new Date(utc0IsoString).getTime()
}

/**
 * Converts a UTC-0 timestamp (common for API data) into browser's timezone
 */
export const toLocalTimestamp = (timestamp: number): number => {
  const isoStringWithoutTimezone = new Date(timestamp)
    .toISOString()
    .replace('.000Z', '')
  return new Date(isoStringWithoutTimezone).getTime()
}
